import 'twin.macro';

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentBlocks from '../sanity/ContentBlocks';
import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import HomeHero from '../components/home/HomeHero';
import CollectionView from '../components/collection/CollectionView';

const Collection = ({ data }) => {
  const { collection, products } = data;

  return (
    <Layout>
      <Seo
        title={collection.title}
        description={collection.body}
        image={
          collection.image &&
          collection.image.asset &&
          collection.image.asset.url
        }
      />
      <HomeHero
        title={collection.title}
        fluid={
          collection.image &&
          collection.image.asset &&
          collection.image.asset.gatsbyImageData
        }
        description={collection.body}
      />
      <CollectionView data={products.nodes} />
      {collection.contentBlocks &&
        collection.contentBlocks.map((block, idx) =>
          React.createElement(ContentBlocks(block._type), {
            idx,
            key: block._key,
            ...block,
          }),
        )}{' '}
    </Layout>
  );
};

Collection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Collection;

export const query = graphql`
  query ($slug: String!) {
    collection: sanityCollection(slug: { current: { eq: $slug } }) {
      _id
      title
      slug {
        current
      }
      body
      brand {
        image {
          asset {
            gatsbyImageData
          }
        }
      }
      image {
        asset {
          url
          gatsbyImageData
        }
      }
      contentBlocks: _rawContentBlocks(resolveReferences: { maxDepth: 10 })
    }
    products: allSanityProduct(
      sort: { fields: order, order: ASC }
      filter: { collection: { slug: { current: { eq: $slug } } } }
    ) {
      nodes {
        id
        order
        title
        body
        description
        numberOfJets
        numberOfSeats
        price
        slug {
          current
        }
        colors {
          title
          value
        }
        condition {
          _id
          title
          slug {
            current
          }
        }
        image {
          asset {
            gatsbyImageData
          }
        }
        brand {
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
